#speakers, .page:not(#theme) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    
    h1 {
        font-family: industry-inc-inline-stroke, sans-serif;
        font-size: 3rem;
    }
    
    h3 {
        font-size: 1.5rem;
    }
}

@media (min-width: 800px) {
    #speakers {
        h3 {
            font-size: 2rem;
        }
    }
}