#theme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .tagline {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        
        &:last-child {
            padding-top: 15px;
        }

        h1 {
            margin: 0;
            font-family: industry-inc-inline-stroke, sans-serif;
            font-size: 3rem;
            line-height: 2.1rem;
            text-align: center;

            &#reshaping {
                font-size: 2.82rem;    
            }
            
            &#location {
                font-size: 2.16rem;
            }            
        }
    }

    #earth {
        width: 80vw;
        height: 80vw;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        z-index: 3;

        & > img {
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            &#core_glow {
                animation: pulse 8s infinite ease;
            }
            
            &#core {
                opacity: 1;
            }
            
            &#back_water {
                opacity: .4;
            }
            
            &#front_water {
                opacity: .7;
            }
            
            &#water_masked {
                opacity: .6;
            }
            
            &#landmass {
                opacity: .9;
            }
        }
        
        #moon {
            position: absolute;
            top: 0;
            right: 0;
            
            height: 15vw;
            width: 15vw; 
            
            & > img {
                z-index: 3;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                &:not(#glow) {
                    opacity: .9;
                }

                &#glow {
                    animation: pulse 5s infinite ease;
                }
            }
        }

    }
}

@media (min-width: 800px) {
    #theme {
        .tagline {
            h1 {
                font-size: 5rem;
                line-height: 3.5rem;

                &#reshaping {
                    font-size: 4.7rem;    
                }
                
                &#location {
                    font-size: 3.6rem;
                }
            }
        }

        #earth {
            width: 60vh;
            height: 60vh;
            
            #moon {
                height: 5vw;
                width: 5vw;
            }
        }
    }
}