* {
    box-sizing: border-box;
}

a {
    color: $tedx-red;
    
    &:hover {
        text-decoration: underline;
        color: $tedx-red;
    }
}


body {
    background-color: $black;
    height: 100vh;
    width: 100vw;
    font-family: 'futura-pt';
    overflow: auto;

}

.page {
    min-height: 100vh;
    padding-top: 60px;
    box-sizing: border-box;
    
    &:last-of-type {
        padding-bottom: 30px;
    }
}

#canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100vh;
    width: 100%;
}

#website {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: $white;
    height: 100vh;
    min-height: 100vh;
    overflow: auto;
    
    #content {
        min-height: 100vh;
        height: 100%
    }
    
    #infos {
        h2, h3 {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
    
    #partners {
        .logos {
            margin-bottom: 3rem;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            align-items: stretch;
            
            a {
                margin-bottom: 1rem;
                
                &:hover {
                    text-decoration: none;

                    img {
                        opacity: 1;
                    }
                }

                img {
                    transition: all .2s linear;
                    max-height: 100px;
                    max-width: 70%;
                    
                    opacity: .8;
                }
            }
        }
    }
    
    #program {
        h1.session {
            font-size: 2rem;
            margin-bottom: 0;
            line-height: 1.5rem;
        }
        
        .speakers_list {

            margin-bottom: 3rem;
            
            .speaker {
                img {
                    height: 15rem;
                }

                &:not(:last-child) {
                    margin-bottom: 3rem;
                }

                h1 {
                    font-size: 1.5rem !important;
                    margin-bottom: 0;
                }

                .speaker_details{
                    & > p {
                        font-size: 1.1rem;
                    }

                    .speaker_bio {
                        font-size: .9rem;
                        font-style: italic;

                        p {
                            margin-bottom: .2rem;
                        }
                    }
                }
            }
        }
    }
}

#cta {
    button {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-color: #ee3724;
        border: 0;
        padding: 5px 15px;
        font-family: 'futura-pt';
        font-size: 1.2rem;
        border-radius: 2px;
        text-transform: uppercase;
        font-weight: 500;
        color: #f2f2f2;
        
        transition: all .5s ease;
    }
    
    &:hover {
        text-decoration: none;
        
        button {
            background-color: #f2f2f2 !important;
            color: #ee3724 !important;
            text-decoration: none !important;
            cursor: pointer;
        }
    }
}

#footer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    background: -moz-linear-gradient(bottom,#000 0,#000 85%,rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(bottom,#000 0,#000 85%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to top,#000 0,#000 85%,rgba(0,0,0,0) 100%);
    flex-direction: row;
    height: 50px;
    justify-content: center;
    flex-wrap: wrap;
    
    span {
        font-size: .9rem;
        width: 100%;
        text-align: center;
        padding-top: 0.6rem;
    }

    a {
        font-family: industry-inc-inline-stroke,sans-serif;
        -webkit-text-stroke: 1px #ee3724;
        line-height: 1rem;
        margin-left: 10px;
        margin-right: 10px;
        z-index: 99;
        font-size: .8rem;
        box-sizing: border-box;
    }
}

#left-hand {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,1+85,0+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 85%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 85%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: industry-inc-inline-stroke, sans-serif;
    
    #menu {
        opacity: 1;
        background-image: url("/assets/img/menubackground.png");
        position: absolute;
        top: 0;
        left: 0;
        z-index: 95;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        transition: all .5s ease;
        
        & > * {
            margin: 0;
            transition: all .5s ease;
            
            &:last-child {
                color: $tedx-red;
                -webkit-text-stroke: 1px $tedx-red;
            }
        }
        
        a {
            color: $white;
            
            &:hover {
                text-decoration: none;
                font-weight: 800;
                -webkit-text-stroke: 1px $white;
            }
        }
        
        &:not(.is-visible) {
            opacity: 0;
            pointer-events: none;
        }
    }
    
    #logo {
        max-height: 35px;
        max-width: none;
    }
    
    .hamburger {
        z-index: 99;
        
        &:focus {
            outline: none;
        }
    }
}

@media (min-width: 800px) {
    #left-hand {
        justify-content: space-between;
        #logo {
            z-index: 99;
        }
        #menu {
            padding-right: 25px;
            align-items: flex-end;
        }
    }
    
    #website {
        &::-webkit-scrollbar {
            width: 5px;
            opacity: .1;
            
            &:hover {
                opacity: 1;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: $black;
            opacity: .1;

            &:hover {
                opacity: 1;
            }
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($white, .5);
            border-radius: 2.5px;
            opacity: .1;

            &:hover {
                opacity: 1;
                background-color: rgba($white, .8);
            }
        }

        #partners {
            .logos {
                width: 75%;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;

                a {
                    margin-bottom: 1rem;
                    margin-left: .5rem;
                    margin-right: .5rem;
                    
                    img {
                        height: 50px;
                        opacity: .8;
                    }
                }
            }
        }
        
        #infos {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
        
        #program {
            .speakers_list {
                width: 75%;

                .speaker {
                    h1 {
                        display: none;
                    }

                    display: flex;
                    align-items: center;

                    &:nth-child(even) {
                        flex-direction: row;

                        .speaker_details {
                            text-align: left;
                        }
                    }

                    &:nth-child(odd) {
                        flex-direction: row-reverse;

                        .speaker_details {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    #footer {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        background: -moz-linear-gradient(bottom,#000 0,#000 85%,rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(bottom,#000 0,#000 85%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to top,#000 0,#000 85%,rgba(0,0,0,0) 100%);
        flex-direction: row;
        height: 30px;
        justify-content: flex-end;

        span {
            font-size: 1rem;
            width: auto;
            text-align: inherit;
            padding-top: 0;
            line-height: 2rem;
        }

        a {
            font-family: industry-inc-inline-stroke,sans-serif;
            -webkit-text-stroke: 1px #ee3724;
            line-height: 2.2rem;
            margin-left: 15px;
            margin-right: 15px;
            z-index: 99;
            font-size: 1rem;
        }
    }
}